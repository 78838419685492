import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "goldenlady",
  casinoId: 100,
  GA_TRACKING:'G-5LRRZPZD2D',

  rivalChatGroupName: "GoldenLady",
  prettyName: "Golden Lady Casino",
  contactEmail: "support@goldenlady.com",
  docsEmail: "documents@goldenlady.email",

  //SEO
  metaDescription: "Online casino slot games on Golden Lady Casino powered by Rival Games. Wide array of slots, table games, and live dealer gambling. Play and spin big bonuses, get rewards from our loyalty program and hit real cash fast. Easy banking, secure payments, smooth transactions: Play like a Golden Lady.",
  logoName: "golden-lady-casino-online-casino-logo.png",
  logoAltText: "Golden Lady Casino online logo with bold yellow text and a lady silhouette emblem popular for slots table games and high-value casino bonuses."
};


